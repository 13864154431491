import localFont from 'next/font/local';

const primary = localFont({
  src: [
    {
      path: './fonts/PantonNarrow-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/PantonNarrow-Bold.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/PantonNarrow-Black.woff',
      weight: '800',
      style: 'normal',
    },
  ],
  variable: '--font-primary',
});

export const fonts = {
  primary,
};
